import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from './index';

interface Vibe {
  data: unknown;
}

// Define a type for the slice state
interface VibeState {
  value: Vibe[];
}

// Define the initial state using that type
const initialState: VibeState = {
  value: [],
};

export const vibesSlice = createSlice({
  name: 'vibes',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addVibe: (state, action: PayloadAction<Vibe>) => {
      state.value.push(action.payload);
    },
  },
});

export const {addVibe} = vibesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.vibes.value;

export const vibesReducer = vibesSlice.reducer;
