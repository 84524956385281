import React from 'react';

import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {SafeAreaView} from 'react-native-safe-area-context';
import {RootStackParamList} from '../navigation/types';
import {StyleSheet} from 'react-native';
import {Button} from '@rneui/base';

type Props = NativeStackScreenProps<RootStackParamList, 'Home'>;

export const HomeScreen = ({navigation}: Props) => {
  return (
    <SafeAreaView style={s.container}>
      <Button
        title="Navigate to NOT_FOUND Screen"
        onPress={() => navigation.navigate('NotFound')}
      />
    </SafeAreaView>
  );
};

const s = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

HomeScreen.displayName = 'HomeScreen';
