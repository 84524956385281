import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from './index';

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

// Define a type for the slice state
interface UserState {
  value: User[];
}

// Define the initial state using that type
const initialState: UserState = {
  value: [],
};

export const usersSlice = createSlice({
  name: 'users',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.value.push(action.payload);
    },
  },
});

export const {addUser} = usersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.users.value;

export const usersReducer = usersSlice.reducer;
