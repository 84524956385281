import {LinkingOptions} from '@react-navigation/native';
import * as ExpoLinking from 'expo-linking';
import {RootStackParamList} from './types';

export const Linking: LinkingOptions<RootStackParamList> = {
  prefixes: [
    ExpoLinking.createURL('/'),
    'vibecheck://',
    'https://vibecheck.wtf',
    'https://azmi-project-1-88845.web.app',
    'https://azmi-project-1-88845.firebaseapp.com',
  ],
  config: {
    initialRouteName: 'Home',
    screens: {
      Home: 'home',
      NotFound: '*',
    },
  },
  enabled: true,
};
