import {configureStore} from '@reduxjs/toolkit';
import {usersReducer} from './users';
import {vibesReducer} from './vibes';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    vibes: vibesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
