import {createStackNavigator} from '@react-navigation/stack';
import {HomeScreen, NotFoundScreen} from '../screens';
import {RootStackParamList} from './types';

const RootStackNavigator = createStackNavigator<RootStackParamList>();

export const RootNavigator = () => (
  <RootStackNavigator.Navigator initialRouteName="Home">
    <RootStackNavigator.Screen name="Home" component={HomeScreen} />
    <RootStackNavigator.Screen name="NotFound" component={NotFoundScreen} />
  </RootStackNavigator.Navigator>
);
