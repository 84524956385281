import React from 'react';

import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {SafeAreaView} from 'react-native-safe-area-context';
import {RootStackParamList} from '../navigation/types';
import {StyleSheet} from 'react-native';
import {Text, Button} from '@rneui/themed';

type Props = NativeStackScreenProps<RootStackParamList, 'NotFound'>;

export const NotFoundScreen = ({navigation}: Props) => {
  return (
    <SafeAreaView style={s.container}>
      <Text style={{fontSize: 32}}>You have been mislead...</Text>
      <Button title={'Go Back'} onPress={() => navigation.goBack()} />
    </SafeAreaView>
  );
};

const s = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

NotFoundScreen.displayName = 'NotFoundScreen';
