import React from 'react';

import {NavigationContainer} from '@react-navigation/native';
import {ThemeProvider} from '@rneui/themed';
import {Provider} from 'react-redux';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Text} from 'react-native';

import {store} from './src/store';
import {theme} from './src/theme';
import {RootNavigator} from './src/navigation/RootNavigator';
import {Linking} from './src/navigation/Linking';

export default function App() {
  return (
    <SafeAreaProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <NavigationContainer
            linking={Linking}
            fallback={<Text>Loading...</Text>}
          >
            <RootNavigator />
          </NavigationContainer>
        </Provider>
      </ThemeProvider>
    </SafeAreaProvider>
  );
}
